import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import banner_1 from './images/banner_1.svg'
const Block_1 = ({selectedLanguage}) => {
    const languageText = selectedLanguage === 'vi' ? 'Phần mềm' : 'Automated';
    const languageText1 = selectedLanguage === 'vi' ? 'báo cáo' : 'Report';
    const languageText2 = selectedLanguage === 'vi' ? 'nhanh' : 'Generation';
    const languageText3 = selectedLanguage === 'vi' ? 'quản trị dự án' : 'Software';
    const languageText4 = selectedLanguage === 'vi' ? 'Thúc đẩy hiệu quả dự án với công cụ quản lý đột phá!' : 'Boost Project Efficiency with our Cutting-Edge Managenement Tool!';

    const languageText5 = selectedLanguage === 'vi' ? 'Trải nghiệm ngay' : 'Try it now!';
    const languageText6 = selectedLanguage === 'vi' ? 'Bảng giá' : 'Pricing';
    return (
        <Container fluid className='block_1 align-items-center d-flex'>
            <Container>
                <Row className='align-items-center'>
                    <Col xs={12} sm={5}>
                        <div className="group_text_banner">
                            <h1>{languageText}<br/><span className='text-underline'><span className='text'>{languageText1}</span> <span className="underline"></span></span>{languageText2} {languageText3}</h1>
                            <p>{languageText4}</p>
                            <div className="group_btn d-flex align-items-center">
                                <Button variant="primary me-0 me-sm-4"><a className='white' href="#footer">{languageText5}</a></Button>
                                <Button variant="light"><a href="#tab1">{languageText6}</a></Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={7}>
                        <img src={banner_1} alt=""/>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Block_1