import { Container, Row, Col } from 'react-bootstrap';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import img1 from './images/block_5_img_1.svg'
const Block_5 = ({selectedLanguage}) => {
    const block_5_data = [
        {
            s_title_en: "Annual Subscription",
            s_title_vi: "Thuê bao hàng năm",
            price_en: '$149',
            price_vi: '3.600.000',
            time: 30,
            activeUser: "100",
            project_en: "Unlimited",
            project_vi: "Không giới hạn",
            data_en: "Redmine, JIRA, Excel, API",
            data_vi: "Redmine, JIRA, Excel, API",
            customization_en: "",
            customization_vi: "",
            maintenance_en: "Free",
            maintenance_vi: "Miễn phí",
            upVersion_en: "Free",
            upVersion_vi: "Miễn phí",
            lisence_en: "1 year",
            lisence_vi: "01 năm",
            promotion_en: '+ 03 months',
            promotion_vi: '+ 03 tháng'
        },
        {
            s_title_en: "Annual Subscription",
            s_title_vi: "Thuê bao hàng năm",
            price_en: '$209',
            price_vi: '5.000.000',
            time: 30,
            activeUser: "200",
            project_en: "Unlimited",
            project_vi: "Không giới hạn",
            data_en: "Redmine, JIRA, Excel, API",
            data_vi: "Redmine, JIRA, Excel, API",
            customization_en: "",
            customization_vi: "",
            maintenance_vi: "Miễn phí",
            maintenance_en: "Free",
            upVersion_vi: "Miễn phí",
            upVersion_en: "Free",
            lisence_vi: "01 năm",
            lisence_en: "01 year",
            promotion_vi: "+ 03 tháng",
            promotion_en: "+ 03 months"
        },
        {
            s_title_vi:"Mua vĩnh viễn",
            s_title_en:'Perpetual license',
            price_vi:'18.000.000',
            price_en:'$749',
            time: 30,
            activeUser: "100",
            project_vi: "Không giới hạn",
            project_en: "Unlimited",
            data_en: "Redmine, JIRA, Excel, API",
            data_vi: "Redmine, JIRA, Excel, API",
            customization_en: "Support available",
            customization_vi: "Có hỗ trợ",
            maintenance_vi:"<ul><li>Miễn phí 3 năm đầu.</li><li>Sau năm thứ 3, tính phí 3.6 triệu/năm.</li><li>Khách" +
                " hàng có thể tự bảo trì và vận hành với chi phí bằng 0</li></ul>",
            maintenance_en:"<ul><li>Free for the first 3 years.</li><li>After the 3rd year, charge 3.6 million/year.</li><li>Guest" +
                "goods can maintain and operate themselves at zero cost</li></ul>",
            upVersion_vi:"<ul><li>Miễn phí 5 năm đầu.</li><li>Sau năm thứ 5, tính phí nâng cấp bằng 35% giá mua" +
                " version mới</li></ul>",
            upVersion_en:"<ul><li>Free for the first 5 years.</li><li>After the 5th year, upgrade fee equal to 35% of purchase price" +
                " new version</li></ul>",
            lisence_vi:"Vĩnh viễn",
            lisence_en:"Forever",

            promotion_vi:"<p>+ 01 năm bảo trì và vận hành miễn phí</p>",
            promotion_en:"<p>+ 01 year of free maintenance and operation</p>"

        },
        {
            s_title_vi: "Gói chuyên gia",
            s_title_en: "Enterprise",
            price_vi:'Liên hệ',
            price_en:'Contact',
            time: 30,
            activeUser: "> 200",
            project_vi: "Không giới hạn",
            project_en: "Unlimited",
            data_en: "Redmine, JIRA, Excel, API",
            data_vi: "Redmine, JIRA, Excel, API",
            customization_en: "Support available",
            customization_vi: "Có hỗ trợ",
            maintenance_en: "Contact",
            maintenance_vi: "Liên hệ",
            upVersion_en: "Contact",
            upVersion_vi: "Liên hệ",
            lisence_en: "Contact",
            lisence_vi: "Liên hệ",
            promotion_en: "Contact",
            promotion_vi: "Liên hệ"
        }
    ]
    const [showTab1, setShowTab1] = useState(true);
    const [showTab2, setShowTab2] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setShowTab1(!showTab1);
        setShowTab2(!showTab2);
        setIsChecked(!isChecked);
    };
    const m1 = selectedLanguage === 'vi' ? 'Trải nghiệm' : 'Experience instantview';
    const m2 = selectedLanguage === 'vi' ? 'miễn phí' : 'for free';
    const m3 = selectedLanguage === 'vi' ? 'instantview' : '';
    const m4 = selectedLanguage === 'vi' ? 'ngay hôm nay' : 'today';
    const m5 = selectedLanguage === 'vi' ? 'Trong trường hợp khách hàng không có server và có nhu cầu thuê hoặc mua' +
        ' server, instantview team sẽ tư vấn để khách hàng mua server phù hợp từ các nhà cung cấp dịch vụ Cloud' +
        ' server hoặc Server vật lý. ' : 'In case the customer does not have a server and needs to rent or buy a server, instantview team will advise the customer to buy a suitable server from Cloud server or physical server service providers. ';
    const m6 = selectedLanguage === 'vi' ? 'Dịch vụ tư vấn này là miễn phí.' : 'This consulting service is free.';

    const m7 = selectedLanguage === 'vi' ? 'Mua ngay' : 'Buy now';

    const m8 = selectedLanguage === 'vi' ? 'Thời gian dùng thử' : 'Trial period';
    const m9 = selectedLanguage === 'vi' ? 'Số lượng Active user tối đa' : 'Maximum number of Active users';
    const m10 = selectedLanguage === 'vi' ? 'Số lượng dự án' : 'Number of projects';
    const m11 = selectedLanguage === 'vi' ? 'Nguồn dữ liệu' : 'Data sources';
    const m12 = selectedLanguage === 'vi' ? ' Tùy biến báo cáo' : 'Customize reports';
    const m13 = selectedLanguage === 'vi' ? 'Bảo trì và vận hành' : 'Maintenance and operation';
    const m14 = selectedLanguage === 'vi' ? 'Nâng cấp lên version mới' : 'Upgrade to new version';
    const m15 = selectedLanguage === 'vi' ? 'Thời hạn sử dụng' : 'Lisence duration';
    const m16 = selectedLanguage === 'vi' ? 'Khuyến mại' : 'Promotion';
    const m17 = selectedLanguage === 'vi' ? 'Tính năng đang được phát triển, chúng tôi sẽ sớm ra mắt' : 'Feature is under development, we will launch it soon';
    const m18 = selectedLanguage === 'vi' ? 'Liên hệ với chuyên gia ngay' : 'Contact an expert now';
    const m19 = selectedLanguage === 'vi' ? 'Ngày' : 'Days';
    const m20 = selectedLanguage === 'vi' ? 'VNĐ' : '';

    return (
        <Container fluid className='block_5 py-5'>
            <Container className='pb-5'>
                <Row className='align-items-center'>
                    <Col xs={12}>
                        <div className="group_text_banner">
                            <h3>{m1}<span className='text-underline'><span className='text ms-2'>{m2}</span> <span className="underline"></span></span> {m3}<br/>{m4}  </h3>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div>
                            <div className='mb-5'>
                                <Container>
                                    <Row className='justify-content-center'>
                                        <Col sm={7} xs={12}>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <div className={`sw-text ${isChecked ? '' : 'active'}`}>On-Premises</div>
                                                <label className="switch mx-3">
                                                    <input type="checkbox" onChange={handleCheckboxChange} />
                                                    <span className="slider round"></span>
                                                </label>
                                                <div className={`sw-text ${isChecked ? 'active' : ''}`}>Cloud-Based</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>

                            </div>

                            {showTab1 && (
                                <div className='tab1' id='tab1'>
                                    <Row className='justify-content-center'>
                                        {
                                            block_5_data.map((item, index)=>(
                                                <Col className='mb-4 mb-md-0' xs={12} sm={2} md={3} key="index">
                                                    <div className="service">
                                                        <div className="s-title">{selectedLanguage === 'en' ? item.s_title_en : item.s_title_vi}</div>
                                                        <div className="s-box">
                                                            <div className="s-list-box">
                                                                <span className="price">{selectedLanguage === 'en' ? item.price_en : item.price_vi}</span>
                                                                {(item.price_vi || item.price_en) !== "Liên hệ" && <span className="small_text">{m20}</span>}
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>{item.time} {m19}</div>
                                                                <span>{m8}</span>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>{item.activeUser}</div>
                                                                <span>{m9}</span>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>{selectedLanguage === 'en' ? item.project_en : item.project_vi}</div>
                                                                <span>{m10}</span>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>{selectedLanguage === 'en' ? item.data_en : item.data_vi}</div>
                                                                <span>{m11}</span>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>{(item.customization_vi || item.customization_en) ? selectedLanguage === 'en' ? item.customization_en : item.customization_vi : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M14.25 2.43457L2.25 14.4346M14.25 14.4346L2.25 2.43458" stroke="#313E4F" stroke-width="3" stroke-linecap="round"/>
                                                                </svg>
                                                                }</div>
                                                                <span>{m12}</span>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>
                                                                    <div dangerouslySetInnerHTML={{__html: selectedLanguage === 'en' ? item.maintenance_en : item.maintenance_vi}}></div>
                                                                    <span>{m13}</span>
                                                                </div>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>
                                                                    <div dangerouslySetInnerHTML={{__html:  selectedLanguage === 'en' ? item.upVersion_en : item.upVersion_vi}}></div>
                                                                    <span>{m14}</span>
                                                                </div>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>{selectedLanguage === 'en' ? item.lisence_en : item.lisence_vi}</div>
                                                                <span>{m15}</span>
                                                            </div>
                                                            <div className="s-list-box">
                                                                <div>
                                                                    <div dangerouslySetInnerHTML={{__html: selectedLanguage === 'en' ? item.promotion_en : item.promotion_vi}}></div>
                                                                    <span>{m16}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <Button variant="primary mt-3 mt-md-5 w-100"><a href="#footer">{m7}</a></Button>
                                                    </div>
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </div>
                            )}
                            {showTab2 && (
                                <div className='tab2'>
                                    <Row className='justify-content-center'>
                                        <Col sm={7} xs={12}>
                                            <img src={img1} alt=""/>
                                            <p className='my-3'>{m17}</p>
                                            <Button variant="primary px-4 w-100">{m18}</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                        <div className='mt-5 text-center note'><i>{m5}<span className='text-blue'>{m6}</span></i></div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Block_5