import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Element } from 'react-scroll';

import LanguageSelector from './LanguageSelector';
import NavigationMenu from './NavigationMenu';
import Block_1 from './Block_1';
import Block_2 from './Block_2';
import Block_3 from './Block_3';
import Block_4 from './Block_4';
import Block_5 from './Block_5';
import Block_6 from './Block_6';
import Block_7 from './Block_7';

function App() {
    const [selectedLanguage, setSelectedLanguage] = useState('vi');

    useEffect(() => {
        const currentLanguage = window.location.pathname.substr(1);
        setSelectedLanguage(currentLanguage);
    }, []);

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    return (
        <Router>
            <div>
                <NavigationMenu selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
                <Routes>
                    <Route path="/" element={<Navigate to={`/${selectedLanguage}`} />} />
                    <Route path="/:lang" element={<LanguageComponent selectedLanguage={selectedLanguage} />} />
                </Routes>
            </div>
        </Router>
    );
}

function LanguageComponent({ selectedLanguage }) {
    return (
        <div>
            <Element name="section1" className="section">
                <Block_1 selectedLanguage={selectedLanguage} />
            </Element>
            <Element name="section2" className="section">
                <Block_2 selectedLanguage={selectedLanguage} />
            </Element>
            <Element name="section3" className="section">
                <Block_3 selectedLanguage={selectedLanguage} />
            </Element>
            <Element name="section4" className="section">
                <Block_4 selectedLanguage={selectedLanguage} />
            </Element>
            <Element name="section5" className="section">
                <Block_5 selectedLanguage={selectedLanguage} />
            </Element>
            <Element name="section6" className="section">
                <Block_6 selectedLanguage={selectedLanguage} />
            </Element>
            <Element name="section7" className="section">
                <Block_7 selectedLanguage={selectedLanguage} />
            </Element>
        </div>
    );
}

export default App;