import { Container, Row, Col } from 'react-bootstrap';

import img1 from './images/block_3_img_1.svg';
import img2 from './images/block_3_img_2.svg';
import img3 from './images/block_3_img_3.svg';
import img4 from './images/block_3_img_4.svg';
import img5 from './images/block_3_img_5.svg';
import img6 from './images/block_3_img_6.svg';
import img7 from './images/block_3_img_7.svg';

import Button from "react-bootstrap/Button";

const Block_3 = ({selectedLanguage}) => {
    const languageText = selectedLanguage === 'vi' ? 'Giải pháp' : 'Solutions';
    const languageText1 = selectedLanguage === 'vi' ? 'dành cho hệ thống báo cáo' : 'for report system';
    const languageText3 = selectedLanguage === 'vi' ? 'Báo cáo tùy chỉnh' : 'Custom reports';
    const languageText4 = selectedLanguage === 'vi' ? 'Liên hệ với chúng tôi để được tư vấn đầy đủ thông tin về các loại báo cáo sẵn có hoặc thiết kế các báo cáo mới theo đúng yêu cầu của bạn' : 'Contact us for comprehensive consultation on the available report types or designing custom reports tailored to your specific requirements';
    const languageText5 = selectedLanguage === 'vi' ? 'Liên hệ ngay' : 'Contact now';

    const block_3_data = [
        {
            image:img1,
            title_vi: "Báo cáo Kiểm soát effort",
            content_vi:"Báo cáo tổng hợp effort định kỳ của  toàn bộ dự án đang triển khai của công ty. Cảnh báo tình" +
                " trạng sử dụng efforts dự án so với Quota được cấp.",
            title_en:'Effort Tracking Report',
            content_en: "Generates regular consolidated reports on the overall project effort for the ongoing projects within the company. Alerts on the utilization status of project efforts compared to the allocated quota.",
        },{
            image:img2,
            title_vi: "Báo cáo chi phí định kỳ",
            content_vi:"<p>Hỗ trợ nhà quản lý theo dõi được tình trạng sử dụng chi phí thực tế định kỳ và tổng thể" +
                " của dự án một cách chính xác, làm căn cứ cảnh báo, báo động khi dự án vượt quá giá trị hợp" +
                " đồng</p>" + "<p>Quản lý chi phí phân bổ (ngoài chi phí tiền lương: VD chi phí điện, thuê văn" +
                " phòng, khấu hao trang thiết bị …) cho từng dự án, từ đó làm căn cứ tính tổng chi phí của dự án.</p>",
            title_en:'Periodic Cost Report',
            content_en: "<p>Support for managers to accurately monitor the periodic actual cost status and overall expenditure of the project, serving as the basis for alerts and alarms when the project exceeds the contract value." +
                "</p>" + "<p>Cost allocation management (in addition to salary costs: e.g., electricity costs, office rent, equipment depreciation, etc.) for each project, thereby serving as the basis for calculating the total project cost.</p>",
        },
        {
            image:img3,
            title_vi: "Kiểm soát non-billable",
            content_vi:"<p>Báo cáo thống kê các nhân sự và % effort của các nhân sự đó tham gia vào các dự án không" +
                " tính phí. </p>" +
                "<p>Cảnh báo các nhân sự chưa có công việc cụ thể hoặc chưa được sắp xếp tham gia vào dự án tính phí" +
                " nào.</p>" +
                "<p>Tính toán % tổng số chi phí/tháng so với quỹ lương.</p></ul>",
            title_en:'Non-billable Control',
            content_en: "<p>Generate reports that summarize personnel and the percentage of their effort involved in" +
                " non-billable projects.<p/>" +
                "<p>Alert on individuals without specific tasks or not assigned to any billable project.</p>" +
                "<p>Calculate the percentage of the total cost per month compared to the salary budget.</p>",
        },
        {
            image:img4,
            title_vi: "Danh sách dự án và thành viên",
            content_vi:"Tổng hợp thông tin về tất cả các dự án trong công ty, bao gồm danh sách thành viên chính và" +
                " phụ tham gia vào mỗi dự án cùng với tỷ lệ % tham gia dự án của từng thành viên.",
            title_en:'Project and Member Listings',
            content_en: "Compile information about all projects within the company, including a list of main and supporting team members for each project, along with the percentage of involvement for each member.",
        },
        {
            image:img5,
            title_vi: "Báo cáo doanh thu",
            content_vi:"Ghi nhận và phân tích số tiền mà công ty kiếm được từ hoạt động kinh doanh theo từng tháng, bao gồm doanh thu của các dự án đang triển khai, doanh thu dự kiến của các dự án đang bidding. Từ đó dự đoán mức doanh thu dự kiến của công ty vào các tháng tiếp theo trong năm.",
            title_en:'Sales Report',
            content_en: "Record and analyze the revenue generated by the company from its business activities on a" +
                " monthly basis, including the revenue from ongoing projects and the expected revenue from projects currently in the bidding phase. Use this data to predict the expected revenue for the company in the upcoming months within the year.",
        }
        ,{
            image:img6,
            title_vi: "Đăng ký lịch làm việc",
            content_vi:"Hỗ trợ đăng ký và duyệt loại hình làm việc (ở văn phòng, tại nhà), lịch nghỉ của các thành" +
                " viên trong dự án / công ty",
            title_en:'Register Working Calendar',
            content_en: "Support the registration and approval of work arrangements (in-office, remote) and leave schedules for project members within the project/company.",
        },
        {
            image:img7,
            title_vi: "Quản lý hồ sơ CV",
            content_vi:"<p>Cập nhật, chỉnh sửa, Export và tải CV của toàn bộ nhân viên theo mẫu chung quy định" +
                    " của công ty" +
                "</p><p>Tải file excel báo cáo, lọc theo nhiều tiêu chí khác nhau như trình độ học vấn, kỹ năng," +
                " ngôn ngữ sử dụng,...</p>",
            title_en:'Resume Management',
            content_en: "<p>Update, edit, export, and download the resumes of all employees according to the" +
                " company's standard template.</p><p>Download Excel reports, filter them based on various criteria such as education level, skills, language proficiency, and more.</p>",
        }
    ]
    return (
        <Container fluid className='block_3 py-5'>
            <Container>
                <Row className='align-items-center'>
                    <Col sm={12}>
                        <div className="group_text_banner">
                            <h3><span className='text-underline'><span className='text'>{languageText}</span> <span className="underline"></span></span>{languageText1}</h3>
                        </div>
                    </Col>
                    <Col className='px-xs-0 px-sm-5'>
                        <Row className='block_3_item'>
                            {
                                block_3_data.map((item, index)=>(
                                    <Col xs={12} className='my-md-5 my-3' key='index'>
                                        <Row className='align-items-center'>
                                            <Col className='block_3_item-img' xs={12} md={6}>
                                                <img src={item.image} alt=""/>
                                            </Col>
                                            <Col className='block_3_item-info' xs={12} md={6}>
                                                <div className="title pb-3">{selectedLanguage === 'en' ? item.title_en : item.title_vi}</div>
                                                <div className="content" dangerouslySetInnerHTML={{__html: selectedLanguage === 'en' ? item.content_en : item.content_vi}}></div>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))
                            }
                        </Row>
                        <Row>
                            <Col>
                                <div className="block_3_contact text-center p-4">
                                    <div className="title pb-3">{languageText3}</div>
                                    <p>{languageText4}</p>
                                    <Button variant="primary px-4 mb-4"><a className='text-white' href="#footer">{languageText5}</a></Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Block_3