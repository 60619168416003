import { Container, Row, Col } from 'react-bootstrap';

import img1 from './images/block_4_img_1.svg';
import img2 from './images/block_4_img_2.svg';
import img3 from './images/block_4_img_3.svg';
import img4 from './images/block_4_img_4.svg';
import img5 from './images/block_4_img_5.svg';
import img6 from './images/block_4_img_6.svg';

import img7 from './images/block_4_img_7.svg';
import img8 from './images/block_4_img_8.svg';
import img9 from './images/block_4_img_9.svg';

const Block_4 = ({selectedLanguage}) => {
    const block_4_data = [
        img1,img2,img3,img4,img5,img6,img7,img8,img9
    ]
    const languageText = selectedLanguage === 'vi' ? 'Dễ dàng' : '';
    const languageText1 = selectedLanguage === 'vi' ? 'tích hợp' : 'And with your';
    const languageText2 = selectedLanguage === 'vi' ? 'với các hệ thống' : 'favorite apps';
    const languageText3 = selectedLanguage === 'vi' ? 'dữ liệu có sẵn' : '';
    const languageText4 = selectedLanguage === 'vi' ? 'Sử dụng các hệ thống tích hợp có sẵn hoặc tạo phiên bản mới dành riêng cho bạn' : 'Use one of our integrations or build your own';

    return (
        <Container fluid className='block_4 py-2 py-md-5'>
            <Container>
                <Row className='align-items-center'>
                    <Col sm={12}>
                        <div className="group_text_banner">
                            <h3>{languageText}<span className='ms-2 text-underline'><span className='text'>{languageText1}</span> <span className="underline"></span></span>{languageText2} <br/>{languageText3}</h3>

                            <p className='text-center mt-3'>{languageText4}</p>
                        </div>
                    </Col>
                    <Col className='px-xs-0 px-sm-5'>
                        <Row className="justify-content-center">
                            <Col xs={12} sm={10}>
                                <Row className="justify-content-center">
                                    <Col className='my-0 my-sm-5 text-center d-flex justify-content-center flex-wrap' xs={12}>
                                        {block_4_data.slice(0, 5).map((item, index) => (
                                            <div className='py-2 py-sm-3 px-2 px-md-5 px-sm-4'><img key={index} src={item} alt='' /></div>
                                        ))}
                                    </Col>
                                    <Col className='my-0 my-sm-5 text-center d-flex justify-content-center flex-wrap'>
                                        {block_4_data.slice(5).map((item, index) => (
                                            <div className='py-2 py-sm-3 px-2 px-md-5 px-sm-4'><img key={index} src={item} alt='' /></div>
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Block_4