import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import logo from './images/logo.svg';
import LanguageSelector from './LanguageSelector';

const NavigationMenu = ({ selectedLanguage, onLanguageChange }) => {
    const [isMenuFixed, setMenuFixed] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            let currentActiveSection = null;

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionBottom = sectionTop + section.offsetHeight;

                if (window.pageYOffset >= sectionTop && window.pageYOffset < sectionBottom) {
                    currentActiveSection = section.id;
                }
            });

            setActiveSection(currentActiveSection);

            // Check if the menu should be fixed
            if (window.pageYOffset > 0) {
                setMenuFixed(true);
            } else {
                setMenuFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const containerStyle = {
        position: isMenuFixed ? 'fixed' : 'relative',
        top: isMenuFixed ? 0 : '',
        left: 0,
        right: 0,
        zIndex: 1000,
        paddingTop: '10px',
        paddingBottom: '10px',
        backgroundColor: '#ffffff',
        border: '1px solid #94A3B8',
        // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    };

    const headInfo = {
        vi: {
            title: 'instantview - Phần mềm báo cáo nhanh quản trị dự án',
            description: 'instantview là hệ thống báo cáo quản trị vận hành trực tuyến, có thể truy cập từ mọi nơi' +
                ' có internet. instantview giúp các nhà quản lý kiểm soát hoạt động, tài nguyên và vận hành công ty, dự án một cách dễ dàng.',
            content_1:'instantview - Phần mềm báo cáo nhanh quản trị dự án' ,
            content_2:'website',
            content_3:'https://instantview.info/',
            content_4: 'instantview - Phần mềm báo cáo nhanh quản trị dự án',
            content_5:'instantview là hệ thống báo cáo quản trị vận hành trực tuyến, có thể truy cập từ mọi nơi có' +
                ' internet. instantview giúp các nhà quản lý kiểm soát hoạt động, tài nguyên và vận hành công ty, dự án một cách dễ dàng.',
            content_6:'/landing.svg'
        },
        en: {
            title: 'instantview - Project management reporting software',
            description: 'instantview is an online management and operations reporting system that can be accessed from anywhere with an internet connection. instantview helps managers easily monitor, control resources and operations of the entire company and each project.',
            content_1:'instantview - Project management reporting software',
            content_2:'website',
            content_3:'https://instantview.info/',
            content_4:'instantview - Project management reporting software',
            content_5: 'instantview is an online management and operations reporting system that can be accessed' +
                ' from anywhere with an internet connection. instantview helps managers easily monitor, control resources and operations of the entire company and each project.',
            content_6:'/landing.svg'
        },
    };

    const currentLanguageInfo = headInfo[selectedLanguage];

    const m1 = selectedLanguage === 'vi' ? 'Trang chủ' : 'Home';
    const m2 = selectedLanguage === 'vi' ? 'Giải pháp' : 'Solution';
    const m3 = selectedLanguage === 'vi' ? 'Tính năng' : 'Feature';
    const m4 = selectedLanguage === 'vi' ? 'Báo giá' : 'Pricing';
    const m5 = selectedLanguage === 'vi' ? 'Hệ thống' : 'System';
    const m6 = selectedLanguage === 'vi' ? 'Tài liệu' : 'Document';
    const m7 = selectedLanguage === 'vi' ? 'Liên hệ' : 'Contact';
    const m8 =
        selectedLanguage === 'vi'
            ? 'https://drive.google.com/drive/folders/1ddkYe02QCTINFBRN1GpIgBVrZVD8rz02?usp=drive_link'
            : 'https://drive.google.com/drive/folders/1ddkYe02QCTINFBRN1GpIgBVrZVD8rz02?usp=drive_link';


    return (
        <>
        <Helmet>
            <title>{currentLanguageInfo.title}</title>
            <meta name="description" content={currentLanguageInfo.description} />
            <meta name="title" content={currentLanguageInfo.content_1} />
            <meta property="og:type" content={currentLanguageInfo.content_2} />
            <meta property="og:url" content={currentLanguageInfo.content_3} />
            <meta property="og:title" content={currentLanguageInfo.content_4} />
            <meta property="og:description" content={currentLanguageInfo.content_5} />
            <meta property="og:image" content={currentLanguageInfo.content_6} />
            <meta property="twitter:card" content={currentLanguageInfo.content_6} />
            <meta property="twitter:url" content="https://instantview.info/" />
            <meta property="twitter:title" content={currentLanguageInfo.title} />
            <meta property="twitter:description" content={currentLanguageInfo.content_5} />
            <meta property="twitter:image" content="/landing.svg" />
            <meta name="keywords" content="project management, project management report, redmine report, jira report, project resource report, phan mem quan ly, quan tri du an" />
        </Helmet>
    <Container className="navigation-box" fluid style={containerStyle}>
        <Container className="navigation">
            <Row className="align-items-center">
                <Col xs={8} sm={5}>
                    <a href="">
                        <img src={logo} alt="" />
                    </a>
                </Col>
                <Col xs={4} sm={7}>
                    <Row className="align-items-center">
                        <Col className="menu-mobile p-sm-0" xs={6} sm={10}>
                            <Navbar expand="lg">
                                <Navbar.Toggle aria-controls="navbar-nav" />
                                <Navbar.Collapse id="navbar-nav">
                                    <Nav className="mr-auto">
                                        <Nav.Link
                                            as={Link}
                                            to="section1"
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            active={activeSection === 'section1'}
                                            onSetActive={() => setActiveSection('section1')}
                                        >
                                            {selectedLanguage === 'vi' ? 'Trang chủ' : 'Home'}
                                        </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="section2"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                active={activeSection === 'section2'}
                                                onSetActive={() => setActiveSection('section2')}
                                            >
                                                {m2}
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="section3"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                active={activeSection === 'section3'}
                                                onSetActive={() => setActiveSection('section3')}
                                            >
                                                {m3}
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="section4"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                active={activeSection === 'section4'}
                                                onSetActive={() => setActiveSection('section4')}
                                            >
                                                {m5}
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="section5"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                active={activeSection === 'section5'}
                                                onSetActive={() => setActiveSection('section5')}
                                            >
                                                {m4}
                                            </Nav.Link>

                                            <Nav.Link
                                                // as={Link}
                                                href={m8}               target="_blank"
                                                // to="section6"
                                                // smooth={true}
                                                // duration={500}
                                                // spy={true}
                                                // active={activeSection === 'section6'}
                                                // onSetActive={() => setActiveSection('section6')}
                                            >
                                                {m6}
                                            </Nav.Link>
                                            <Nav.Link
                                                as={Link}
                                                to="section7"
                                                smooth={true}
                                                duration={500}
                                                spy={true}
                                                active={activeSection === 'section7'}
                                                onSetActive={() => setActiveSection('section7')}
                                            >
                                                {m7}
                                            </Nav.Link>
                                        </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                        <Col className="language-selector" xs={6} sm={2}>
                            <LanguageSelector
                                selectedLanguage={selectedLanguage}
                                onLanguageChange={onLanguageChange}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </Container>
        </>
    );
};

export default NavigationMenu;