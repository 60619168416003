import React from 'react';

import android from './images/block_6_img_1.svg';
import ios from './images/block_6_img_2.svg';

const Block_6 = ({ selectedLanguage }) => {
    const languageText = selectedLanguage === 'vi' ? 'Tải và cài đặt' : 'Download and Install';
    const languageText1 = selectedLanguage === 'vi' ? 'ứng dụng di động' : 'mobile application';
    const languageText2 = selectedLanguage === 'vi' ? 'instantview tại đây' : 'instantview here';

    return (
        <div className='block_6 py-5'>
            <div className='pb-5'>
                <div className='align-items-center'>
                    <div>
                        <div>
                            <h3>
                <span className='text-underline'>
                  <span className='text'>{languageText}</span> <span className="underline"></span>
                </span>
                                {languageText1} <br />{languageText2}
                            </h3>
                        </div>
                    </div>
                    <div className='px-xs-0 px-sm-5 mt-5'>
                        <div className="justify-content-center">
                            <div className="d-flex align-items-center justify-content-center">
                                <a className='mx-2' href="https://play.google.com/store/apps/details?id=com.tomdev01.ReportingSystem
" target='_blank'><img src={android} alt="" /></a>
                                <a className='mx-2' href="https://apps.apple.com/vn/app/instant-view/id6450174035" target='_blank'><img src={ios} alt="" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Block_6;