import { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import axios from 'axios';

import logo from './images/logo.svg';
import youtube from './images/youtube.svg';
import facebook from './images/facebook.svg';
import linkein from './images/linkein.svg';
import modal1 from './images/modal1.svg';

const Block_7 = ({ selectedLanguage }) => {
    const [showModal, setShowModal] = useState(false);
    const [captchaRefreshed, setCaptchaRefreshed] = useState(false);
    const [captchaImageUrl, setCaptchaImageUrl] = useState('');
    const [captKey, setCaptKey] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [captchaError, setCaptchaError] = useState(false);
    useEffect(() => {
        fetchCaptchaImage();
    }, []);

    const fetchCaptchaImage = () => {
        const randomNumber = Math.floor(Math.random() * 1000000); // Số ngẫu nhiên
        const captKey = Math.random().toString(36).substring(2); // Chuỗi ngẫu nhiên
        setCaptKey(captKey);
        const captchaImageUrl = `https://authgate.one/ag/api/v1/captcha/generator/ITV/${captKey}?r=${randomNumber}`;
        setCaptchaImageUrl(captchaImageUrl);
        setCaptchaRefreshed(!captchaRefreshed);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        setPhoneNumberError('');
        setEmailError('');
        setCaptchaError(false);

        const form = event.target;
        const name = form.elements.name.value;
        const email = form.elements.email.value;
        const phoneNum = form.elements.phoneNum.value;
        const message = form.elements.message.value;
        const captcha = form.elements.captcha.value;

        if (!phoneNum) {
            setPhoneNumberError('Phone number is required.');
            return;
        }

        if (!email) {
            setEmailError('Email is required.');
            return;
        }
        if (!captcha) {
            setCaptchaError(true);
            return;
        }
        const requestBody = {
            code: 'ITV',
            name: name,
            email: email,
            phoneNum: phoneNum,
            message: message,
            captvalue: captcha,
            captkey: captKey,
        };

        const contactUrl = 'https://authgate.one/ag/api/v1/pub/contacts';
        axios
            .post(contactUrl, requestBody)
            .then((response) => {
                console.log('Message sent successfully:', response.data);
                form.reset();
                setShowModal(true);
            })
            .catch((error) => {
                console.error('Error sending contact message:', error);
            });
    };
    const handleCloseModal = () => {
        setShowModal(false);
        fetchCaptchaImage();
    };

    const m1 = selectedLanguage === 'vi' ? 'Họ tên' : 'Name';
    const m2 = selectedLanguage === 'vi' ? 'Email' : 'Email';
    const m3 = selectedLanguage === 'vi' ? 'Số điện thoại' : 'Phone number';
    const m4 = selectedLanguage === 'vi' ? 'Nội dung' : 'Content';
    const m5 = selectedLanguage === 'vi' ? 'Gửi ngay' : 'Send';
    const m6 = selectedLanguage === 'vi' ? 'Đóng' : 'Close';
    const m7 = selectedLanguage === 'vi' ? 'Liên hệ' : 'Contact';

    const m8 = selectedLanguage === 'vi' ? 'Cảm ơn bạn đã gửi thông tin liên hệ! Chúng tôi sẽ liên lạc với bạn trong thời gian sớm nhất.' : 'Thank you for providing your contact information! We will get in touch with you as soon as possible.';
    const m9 = selectedLanguage === 'vi' ? 'Cảm ơn bạn đã quan tâm đến sản phẩm instantview.' : 'Thanks you for contact us';
    const m10 = selectedLanguage === 'vi' ? 'Chúng tôi sẽ phản hồi trong thời gian sớm nhất' : 'We do appreciate your visit. We will get back to you soon.';


    return (
        <Container fluid className='block_7 pt-5 pb-4'>
            <Container>
                <Row className='align-items-center pb-4'>
                    <Col sm={6} xs={12}>
                        <img src={logo} alt='' />
                        <div className='f-title text-blue my-3'>
                            <b>{m7}</b>
                        </div>
                        <p>
                            {m9} <br />
                            {m10}
                        </p>
                    </Col>
                    <Col sm={6} xs={12}>
                        <Form id='footer' onSubmit={handleSubmit}>
                            <Form.Group className='mb-3' controlId='formName'>
                                <Form.Control type='text' name='name' placeholder={m1} />
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='formEmail'>
                                <Form.Control
                                    type='email'
                                    name='email'
                                    placeholder={m2}
                                    isInvalid={!!emailError}
                                />
                                <Form.Control.Feedback type='invalid'>{emailError}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className='mb-3' controlId='formPhone'>
                                <Form.Control
                                    type='text'
                                    name='phoneNum'
                                    placeholder={m3}
                                    isInvalid={!!phoneNumberError}
                                />
                                <Form.Control.Feedback type='invalid'>{phoneNumberError}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-3' controlId='formMessage'>
                                <Form.Control
                                    as='textarea'
                                    rows={3}
                                    name='message'
                                    placeholder={m4}
                                />
                            </Form.Group>

                            <div className='d-flex justify-content-between flex-wrap flex-md-wrap align-items-center'>
                                <Form.Group className='mb-3 mb-md-0 d-flex align-items-center' controlId='formCaptcha'>
                                    <img src={captchaImageUrl} alt='Captcha' />
                                    <Form.Control
                                        type='text'
                                        name='captcha'
                                        placeholder='Captcha'
                                        isInvalid={captchaError}
                                        className='captcha_in'
                                    />
                                    {captchaError && <Form.Control.Feedback type='invalid'>Incorrect captcha code.</Form.Control.Feedback>}
                                </Form.Group>
                                <Button className='px-5' variant='primary' type='submit'>
                                    {m5}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <div className="footer">
                    <Row>
                        <Col sm={6} xs={12}>
                            <span>© VietSoftware International 2023</span>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className="d-flex align-items-center justify-content-between justify-content-md-end">
                                <div className="link">
                                    <a href="https://www.youtube.com/channel/UCbzgyuU8XqBu2g-Y0TvPiag"><img className='me-2' src={youtube} alt=""/>Youtube</a>
                                </div>
                                <div className="link mx-4">
                                    <a href="https://www.facebook.com/VSII.Fanpage
"><img className='me-2' src={facebook} alt=""/>Facebook</a>
                                </div>
                                <div className="link">
                                    <a href="https://www.linkedin.com/company/vietsoftware-international/
"><img className='me-2' src={linkein} alt=""/>LinkedIn</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title>
                        <img className='modal1' src={modal1} alt=""/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-center mb-0'>{m8}</p>
                </Modal.Body>
                <Modal.Footer className='p-4'>
                   <div className="d-flex justify-content-center">
                       <Button className='px-5' variant="primary" onClick={handleCloseModal}>
                           {m6}
                       </Button>
                   </div>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default Block_7