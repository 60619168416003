import { Container, Row, Col } from 'react-bootstrap';

import img1 from './images/block_2_img_1.svg';
import img2 from './images/block_2_img_2.svg';
import img3 from './images/block_2_img_3.svg';
import img4 from './images/block_2_img_4.svg';

const Block_2 = ({ selectedLanguage }) => {
    const block_2_data = [
        {
            image:img1,
            title_vi: "Tạo báo cáo theo thời gian thực",
            title_en:"Real-time customizable reports",
            content_en: "Generate reports at any given time based on the actual needs of the project, supporting managers to access reports on individual personnel, each project, and the overall company",
            content_vi: "Xuất báo cáo tại thời điểm bất kỳ dựa trên nhu cầu thực tế của dự án, hỗ trợ các nhà quản lý có thể xem được báo cáo về từng nhân sự, từng dự án và tổng hợp toàn bộ công ty"
        },{
            image:img2,
            title_vi: "Kết nối và cập nhật dữ liệu tự động",
            content_vi:"Có sẵn kết nối với các hệ thống quản lý dự án phổ biến như JIRA, redmine, Excel, hoặc các" +
                " nguồn dữ liệu khác qua API",
            title_en:'Connect and update data automatically',
            content_en: "Integration with popular project management systems such as JIRA, Redmine, Excel, or other data sources via APIs is available",
        },
        {
            image:img3,
            title_vi: "Trực quan hóa dữ liệu",
            content_vi:"Tập hợp tất cả các dữ liệu dưới dạng bảng số liệu thống kê, biểu đồ trực quan, dễ hiểu trên" +
                " một trang báo cáo chung",
            title_en:'Data Visualization',
            content_en: "Visualize reports in easy-to-understand charts and graphs on a single dashboard",
        },
        {
            image:img4,
            title_vi: "Hỗ trợ tối ưu hóa quy trình làm việc",
            content_vi:"Có cái nhìn tổng quan, góp phần đưa ra cảnh báo, dự đoán tương lai, đánh giá và điều chỉnh" +
                " về" +
                " toàn bộ quy trình quản lý dự án. Nâng cao hiệu suất làm việc và hiệu quả quản lý dự án",
            title_en:'Support for optimizing workflow processes',
            content_en: "Provides an overview, contributes to alerts, predicts the future, evaluates and adjusts the" +
                " entire project management process. Enhances work efficiency and project management effectiveness",
        }
    ]
    const m1 = selectedLanguage === 'vi' ? '' : 'Send more time';
    const m2 = selectedLanguage === 'vi' ? 'Phân tích' : 'analyzing data';
    const m3 = selectedLanguage === 'vi' ? 'dữ liệu thay vì nhập liệu' : 'and less time preparing them';
    return (
        <Container fluid className='block_2 py-5'>
            <Container>
                <Row className='align-items-center'>
                    <Col sm={12}>
                        <div className="group_text_banner">
                            <h3>{m1}<span className='text-underline ms-2'><span className='text'>{m2}</span> <span className="underline"></span></span>{m3}</h3>
                        </div>
                    </Col>
                    <Col className='px-xs-0 px-sm-5'>
                        <Row>
                            {block_2_data.map((item, index) => (
                                <Col sm={6} xs={12} className='my-5' key={index}>
                                    <img src={item.image} alt=""/>
                                    <div className="title py-3">
                                        {selectedLanguage === 'en' ? item.title_en : item.title_vi}
                                    </div>
                                    <div className="content">
                                        {selectedLanguage === 'en' ? item.content_en : item.content_vi}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                </Row>
            </Container>
        </Container>
    )
}

export default Block_2