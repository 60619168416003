import React from 'react';
import { useNavigate } from 'react-router-dom';

const LanguageSelector = ({ selectedLanguage, onLanguageChange }) => {
    const navigate = useNavigate();

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        onLanguageChange(selectedLanguage);

        // Change the URL based on the selected language
        navigate(`/${selectedLanguage}`);
    };

    return (
        <div>
            <select value={selectedLanguage} onChange={handleLanguageChange}>
                <option value="vi">Vi</option>
                <option value="en">En</option>
            </select>
        </div>
    );
};

export default LanguageSelector;